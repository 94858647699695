<template>
  <span>
    <b-card class="mb-4">
      <b-card-text>
        <b-form @submit.prevent>
          <b-row>
            <!-- username -->
            <b-col cols="9">
              <b-form-group
                label-for="fh-search"
                class="input-group-merge"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="fh-search"
                    v-model="searchText"
                    placeholder="Please Enter Any of: Company UID"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <span class="clx-inline-spacing">
                <clx-submit-button
                  title-text="Search"
                  :disabled="!validationSearch"
                  @click="search(10)"
                />
                <clx-submit-button
                  title-text="Reset"
                  @click="resetSearch"
                />
              </span>
            </b-col>
          </b-row>
        </b-form>
      </b-card-text>
    </b-card>
    <b-card>
      <b-row>
        <b-spinner
          v-if="show"
          class="ml-2 mb-2"
          label="Loading..."
        />
      </b-row>
      <b-row class="pl-1">
        <vue-good-table
          :columns="columns"
          :rows="items"
          hover
          striped
          theme="polar-bear"
          @on-row-click="onRowSelected"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <div
              v-if="props.column.field === 'activity_on'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ formatDate(props.row.activity_on) }}</span>
            </div>
            <div
              v-else-if="props.column.field === 'uid'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.company.uid }} ({{ props.row.seances_count }} seances for {{ props.row.trainee_count }} trainees) </span>
            </div>
            <span v-else-if="props.column.field === 'actions'">
              <a @click="onRowSelected( {row:props.row} )">
                <feather-icon icon="InfoIcon" />
                <span class="align-middle ml-50">View</span>
              </a>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-row>
    </b-card>
  </span>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BSpinner,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import ClxSubmitButton from '@/views/components/clx-button/Submit.vue'
import { premiumSeances } from '@/views/pages/seances/premiumSeances'

export default {
  components: {
    VueGoodTable,
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BForm,
    BCardText,
    ClxSubmitButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      keyTyping: false,
      show: true,
      searchText: '',
      selectedRowId: 0,
      items: [],
      columns: [
        {
          label: 'Last Activity Date',
          field: 'activity_on',
          sortable: false,
          tdClass: 'text-left',
          // dateInputFormat: 'yyyy-MM-dd',
          // dateOutputFormat: 'MMM do yy',
        },
        {
          label: 'Company UID',
          field: 'uid',
          sortable: false,
          tdClass: 'text-left',
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    validationSearch() {
      return this.searchText.length > 1
    },
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    this.keyTyping = false
    window.addEventListener('keydown', e => {
      this.keyTyping = true
      if (e.key === 'Enter') {
        this.keyTyping = false
        this.search(10)
      }
    })
  },
  mounted() {
    const searchData = {
      recordCount: 5, filterBy: 'c',
    }
    const { fetchSeancesActivity } = premiumSeances()
    fetchSeancesActivity(searchData)
      .then(response => {
        if (response.data.data.length > 0) {
          this.hasResult = true
        } else {
          this.hasResult = false
        }
        this.items = response.data.data
        this.show = false
      })
  },
  methods: {
    formatDate(date) {
      return new Date(date).toISOString().slice(0, 10)
    },
    onRowSelected(row) {
      this.selectedRowId = row.row.company.id
      this.$router.push({ name: 'seances.search-list', params: { q: row.row.company.uid, r: 'c' } })
    },
    resetSearch() {
      this.searchText = ''
      this.search(5)
    },
    search(recordCounts = 10) {
      this.show = true
      const searchData = {
        recordCount: recordCounts, filterBy: 'c', q: this.searchText,
      }
      const { fetchSeancesActivity } = premiumSeances()
      fetchSeancesActivity(searchData)
        .then(response => {
          if (response.data.data.length > 0) {
            this.hasResult = true
          } else {
            this.hasResult = false
          }
          this.items = response.data.data
          this.show = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~vue-good-table/src/styles/style.scss';
</style>
